import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import { Link } from "gatsby"

const Holder = styled.div`
  margin: 1rem;
  background-color: ${props => props.theme.colours.grey};
  border-radius: 10px;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 2rem 4rem;
  }

  > a {
    display: grid;
    grid-template-rows: auto auto;
    grid-gap: 1.5rem;
    padding: 1.5rem;
    @media (${(props) => props.theme.breakpoints.md}) {
      grid-template-columns: 3fr 5fr;
      grid-template-rows: unset;
    }

    > :first-child {
      font-family: "Circular Bold", Helvetica, Arial, sans-serif;
    }
  }
`

const Inner = styled.div`
  background-color: ${props => props.theme.colours.white};
  border-radius: 10px;

  > :first-child {
    margin: 2rem 0 0 2rem;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 1.5rem;
    > :first-child {
      margin: 0;
    }
  }
`

const IconHolder = styled.div`
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: unset;
  }

  .gatsby-image-wrapper img, .gatsby-image-wrapper {
    object-fit: contain !important;
    aspect-ratio: 3/2;
    @media (${(props) => props.theme.breakpoints.md}) {
      aspect-ratio: 1/1;
    }
  }
`

Pillar.propTypes = {
  title: PropTypes.object.isRequired,
  iconOne: PropTypes.object.isRequired,
  iconTwo: PropTypes.object.isRequired,
  iconThree: PropTypes.object.isRequired,
}

function Pillar({ title, link, iconOne, iconTwo, iconThree }) {
  return (
    <Holder>
      <Link to={"/" + link.uid}>
        <PrismicRichText field={title.richText} />
        <Inner>
          <p className="ReplicaBold">Areas of focus</p>
          <IconHolder>
            <GatsbyImage alt={iconOne.alt || "Icon 1"} image={iconOne.gatsbyImageData} />
            <GatsbyImage alt={iconTwo.alt || "Icon 2"} image={iconTwo.gatsbyImageData} />
            <GatsbyImage alt={iconThree.alt || "Icon 3"}
                         image={iconThree.gatsbyImageData} />
          </IconHolder>
        </Inner>
      </Link>
    </Holder>
  )
}

export default Pillar