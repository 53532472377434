import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";

import Container from "../atoms/Container";
import TwoColumn from "../molecules/TwoColumn";

const Background = styled.div`
  background-color: ${(props) => props.theme.colours[props.bg_colour]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 4rem 2rem;
  }

  .Logo {
    max-width: 200px;
    @media (${(props) => props.theme.breakpoints.lg}) {
      max-width: 300px;
    }
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: auto;
`;

const Inner = styled.span``;

const H4pad = styled.h4`
  position: relative;
`;

const Grid = styled.li`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-gap: 1px;
  margin: 0 1rem;
  padding: 2rem 0;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: calc(50% - 2rem) auto auto;
    grid-template-rows: auto;
    column-gap: 4rem;
    padding: 6rem 0;
    margin: 0 4rem;
  }
`;

const TextWrapper = styled.div`
  @media (${(props) => props.theme.breakpoints.lg}) {
    h3, h4 {
      margin-top: 0;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colours[props.row_colour]};
  color: ${(props) => props.theme.colours[props.font_colour]};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  justify-items: space-between;
  align-items: center;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 4rem;
    padding: 0 4rem;
  }

  > h4 {
    padding: 2rem 1rem;
    @media (${(props) => props.theme.breakpoints.lg}) {
      padding: 0;
    }
  }
`;

export default function SliceDivisionBanner({ slice }) {
  return (
    <Container>
      <Background bg_colour={slice.bg_colour.toLowerCase()}>
        <GatsbyImage
          className="Logo"
          image={slice.logo.gatsbyImageData}
          alt={slice.logo.alt}
        />
      </Background>
      <ImageHolder>
        <GatsbyImage
          image={slice.hero_image.gatsbyImageData}
          alt={slice.hero_image.alt}
        />
      </ImageHolder>
      <TwoColumn>
        <H4pad className="CircularBold line">{slice.headline.text}</H4pad>
        <Inner>
          <h4>{slice.headline_description.text}</h4>
        </Inner>
      </TwoColumn>

      {slice.first_row_text.text && <Wrapper
        row_colour={slice.row_colour.toLowerCase()}
        font_colour={slice.font_colour.toLowerCase()}
      >
        <GatsbyImage
          className={slice.first_image_padded_width && "paddedMargin"}
          image={slice.first_row_image.gatsbyImageData}
          alt={slice.first_row_image.alt}
        />
        <h4>{slice.first_row_text.text}</h4>
      </Wrapper>}
      {slice.second_row_headline.text &&
        <Grid>
          <GatsbyImage
            image={slice.second_row_image.gatsbyImageData}
            alt={slice.second_row_image.alt}
          />
          <TextWrapper>
            <h4>{slice.second_row_headline.text}</h4>
            <RichText render={slice.second_row_description.richText} />
          </TextWrapper>
        </Grid>}
      {slice.third_row_text.text && <Wrapper
        row_colour={slice.row_colour.toLowerCase()}
        font_colour={slice.font_colour.toLowerCase()}
      >
        <h4>{slice.third_row_text.text}</h4>
        <GatsbyImage
          className={slice.third_image_padded_width && "paddedMargin"}
          image={slice.third_row_image.gatsbyImageData}
          alt={slice.third_row_image.alt}
        />
      </Wrapper>}
      {slice.fourth_row_headline.text && <Grid>
        <GatsbyImage
          image={slice.fourth_row_image.gatsbyImageData}
          alt={slice.fourth_row_image.alt}
        />
        <TextWrapper>
          <h4>{slice.fourth_row_headline.text}</h4>
          <RichText render={slice.fourth_row_description.richText} />
        </TextWrapper>
      </Grid>}
    </Container>
  );
}

SliceDivisionBanner.propTypes = {
  slice: PropTypes.shape({
    logo: PropTypes.object,
    hero_image: PropTypes.object,
    headline: PropTypes.object,
    headline_description: PropTypes.object,
    first_row_image: PropTypes.object,
    first_row_text: PropTypes.object,
    second_row_image: PropTypes.object,
    second_row_headline: PropTypes.object,
    second_row_description: PropTypes.object,
    third_row_image: PropTypes.object,
    third_row_text: PropTypes.object,
    fourth_row_image: PropTypes.object,
    fourth_row_headline: PropTypes.object,
    fourth_row_description: PropTypes.object,
    row_colour: PropTypes.oneOf([
      "navy",
      "white",
      "sheratongold",
      "sheratongrey",
      "minikinscream",
      "minikinsorange",
    ]),
    font_colour: PropTypes.oneOf(["navy", "white"]),
    bg_colour: PropTypes.oneOf(["navy", "white"]),
  }),
};

SliceDivisionBanner.defaultProps = {
  row_colour: "white",
  font_colour: "navy",
  bg_colour: "navy",
};
