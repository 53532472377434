import React from "react";
import styled from "styled-components";
import RichText from "prismic-reactjs/src/Component";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import Container from "../atoms/Container";

const Holder = styled.div`
  color: ${(props) => props.theme.colours.navy};
  background-color: ${(props) => props.theme.colours.white};
  padding: 1rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  margin: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 4rem 0;
  }
`;

const Inner = styled.span`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
`;

const TwoColumnMarginless = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2rem;
  padding-bottom: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 2rem 0;
    column-gap: 4rem;
  }
`;

const Cards = styled.div`
  background-color: ${(props) => props.theme.colours[props.color]};
  color: ${(props) => props.theme.colours[props.font_color]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.5rem 2rem;
  height: fit-content;

  &:last-child {
    font-family: "Replica Bold", Helvetica, Arial, sans-serif;
  }
`;

export default function SliceCardText({ slice, cards }) {
  return (
    <Container>
      <Holder>
        <TwoColumnMarginless>
          <h3 className="CircularLight">{slice.title.text}</h3>
          <Inner>
            {cards.map((card, i) => (
              <Cards key={i} color={card.color} font_color={card.font_color}>
                {card.title.richText && (
                  <RichText render={card.title.richText} />
                )}
                <GatsbyImage image={card.icon.gatsbyImageData} />
                <RichText render={card.desc.richText} />
              </Cards>
            ))}
          </Inner>
        </TwoColumnMarginless>
      </Holder>
    </Container>
  );
}

SliceCardText.propTypes = {
  title: PropTypes.object,
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
};
