import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import Container from "../atoms/Container";
import Arrow from "../../../static/up-arrow.inline.svg";

const Holder = styled.div`
  color: ${(props) => props.theme.colours.navy};
  background-color: ${(props) => props.theme.colours.white};
  margin: 0 1rem;
  padding: 0;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 4rem 0;
  }
`;

const Grid = styled.div`
  display: grid;
  background-color: ${(props) => props.theme.colours.white};
  border: none;
  padding: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 4rem;
  align-items: center;
  cursor: pointer;
  white-space: normal;

  a {
    border-bottom: 1px solid ${(props) => props.theme.colours.navy};
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: ${(props) => props.theme.colours.grey};
    }
  }

  a :last-child {
    border: none;
  }

  a :first-child {
    @media (${(props) => props.theme.breakpoints.lg}) {
      border-top: 1px solid ${(props) => props.theme.colours.navy};
    }
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    a :first-child {
      border-top: none;
    }
  }
`;

const Wrapper = styled.li`
  display: grid;
  grid-template-columns: 1fr 1.5rem;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }

  > h4 {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    text-decoration: none !important;
    color: ${(props) => props.theme.colours.navy};
  }

  > svg {
    height: 1.5rem;
    width: auto;
    transform: rotate(45deg);
    transition: all 250ms ease-in-out;
  }
`;

export default function SliceTwoColLinks({ links }) {
  return (
    <Container>
      <Holder>
        <Grid>
          {links.map((link, i) => (
            <Link
              activeClass="active"
              className="more"
              to={link.link_display_text.text}
              spy={true}
              smooth={true}
              duration={1000}
              offset={-200}
              key={i}
            >
              <Wrapper>
                <h4>{link.link_display_text.text}</h4>
                <Arrow />
              </Wrapper>
            </Link>
          ))}
        </Grid>
      </Holder>
    </Container>
  );
}

SliceTwoColLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

