import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RichText from "prismic-reactjs/src/Component";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import TwoColumn from "../molecules/TwoColumn";
import VideoOverlay from "../atoms/VideoOverlay";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import PlayButton from "../../assets/images/play.inline.svg";

const Holder = styled.div`
  padding-top: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 4rem 0;
  }

  h3 {
    @media (${(props) => props.theme.breakpoints.lg}) {
      position: relative;
      margin: 2rem 0;
    }
  }
`;

const Media = styled.div`
  padding: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 0 4rem;
  }
`;

const Button = styled.button`
  width: 100%;
  margin: 0;
  position: relative;
  border: none;
  outline: none;
  padding: 0;

  &:hover img, .gatsby-image-wrapper {
    filter: brightness(50%);
  }

  &:hover svg {
    opacity: 1
  }

  svg {
    transition: opacity 200ms ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    opacity: 0;
    height: 6rem;
    width: auto;
  }
`;

const Inner = styled.span`
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 1rem 0;
    p {
      margin: 0;
    }
  }
`;

const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  height: auto;

  img, .gatsby-image-wrapper {
    transition: filter 200ms ease-in-out;
  }
`;

const ContentHolder = styled.div`
  position: relative;

  > div {
    display: grid;
    grid-template-columns: auto 1.5fr;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colours[props.border_colour]};
  margin: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
  }
}
`;

export default function SliceVideoEmbed({ slice }) {
  const videoId = slice.video.thumbnail_url.match(/[^/]+(?=\/[^/]+$)/)?.[0];
  const [overlayOpen, setOverlayOpen] = useState(false);

  const { tl, holderRef, gsap, st, q } = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = st.matchMedia({
        // desktop
        "(min-width: 576px)": function() {
          gsap.timeline({
            scrollTrigger: {
              trigger: holderRef.current,
              start: "top 90%",
              end: "bottom bottom",
              scrub: 0.25,
            },
          }).from(q(`.container`), {
            yPercent: 15,
            autoAlpha: 0,
            duration: 0.25,
            stagger: 0.5,
            ease: "power1.out",
          });
        },
        // Add more breakpoints if needed.
        // See https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.matchMedia()
      });
    }
  });

  return (
    <>
      <Container>
        <Holder ref={holderRef} border_colour={slice.border_colour}>
          <div className="container">
            <Media>
              <Button onClick={() => setOverlayOpen(true)} className="button">
                <PlayButton />
                <ImageHolder>
                  {slice.optional_thumbnail.gatsbyImageData ? <GatsbyImage
                    image={slice.optional_thumbnail.gatsbyImageData}
                    alt={slice.optional_thumbnail.alt}
                    style={{ aspectRatio: "16/9" }}
                  /> : <img
                    src={slice.video.thumbnail_url}
                    srcSet={videoId ? `https://i.ytimg.com/vi_webp/${videoId}/hqdefault.webp 1x, https://i.ytimg.com/vi_webp/${videoId}/maxresdefault.webp 2x` : ""}
                    alt={slice.video.title}
                    style={{ width: "100%", height: "auto", aspectRatio: "16/9" }}
                  />}
                </ImageHolder>
              </Button>
            </Media>
            <TwoColumn className="container">
              <ContentHolder>
                <div className="line">
                  {slice.title.text ? <h4>{slice.title.text}</h4> : <h4>{slice.video.title}</h4>}
                </div>
              </ContentHolder>

              <Inner>
                {slice.description.richText && (
                  <RichText render={slice.description.richText} />
                )}
                {slice.video.embed_url && (
                  <button className="button outline large"
                          onClick={() => setOverlayOpen(true)}>{slice.button_display_text.text}
                  </button>
                )}
              </Inner>
            </TwoColumn>
          </div>
        </Holder>
        <Border border_colour={slice.border_colour}></Border>
      </Container>
      <VideoOverlay closeHandler={() => setOverlayOpen(false)} open={overlayOpen} embed={slice.video}
                    openVideo={() => setOverlayOpen(true)} />
    </>
  );
}

SliceVideoEmbed.propTypes = {
  slice: PropTypes.shape({
    optional_thumbnail: PropTypes.object,
    title: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired,
    button_display_text: PropTypes.object,
    video: PropTypes.any.isRequired,
    border_colour: PropTypes.oneOf(["navy", "white"]),
  }),
};

SliceVideoEmbed.defaultProps = {
  border_colour: "navy",
};