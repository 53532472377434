import React from "react"
import UNSDCard from "../molecules/UNSDCard"
import styled from "styled-components"
import Container from "../atoms/Container"
import { goals } from "../../assets/goals"
import PropTypes from "prop-types"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 4rem 1rem;
  text-align: center;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 4rem;
  }
`

const Carousel = styled.div`
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;

  > div {
    margin-right: 2rem;
  }

  > div:last-child {
    margin-right: 0;
  }
`

SliceUNSDGoals.propTypes = {
  items: PropTypes.array.isRequired,
}

function SliceUNSDGoals({ items }) {
  return (
    <Container>
      <Holder>
        <div>
          <h4 className="CircularBold">United Nations Sustainable Development Goals</h4>
          <h4 className="CircularLight">
            At Simba, we have developed our Simba Conscious framework and action plan with
            the UN SDG’s in mind. As we implement this plan, we are proud to be contributing to this global framework
            for impact.
          </h4>
        </div>
        <Carousel>
          {goals.map((goal, index) => {
            return items.map(item => {
              return index === item.number - 1 ? <UNSDCard key={index} image={goal} /> : null
            })
          })}
        </Carousel>
      </Holder>
    </Container>
  )
}

export default SliceUNSDGoals