import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";

const Background = styled.div`
  padding: 2rem 0;
  margin: 0 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 6rem 0;
  }

  * {
    max-width: 1200px;
    margin: auto;
  }
`;

export default function SliceSingleImage({ slice }) {
  return (
    <Container>
      <Background>
        {slice.image.gatsbyImageData && (
          <GatsbyImage
            imgStyle={{ objectFit: "fill" }}
            image={slice.image.gatsbyImageData}
            alt={slice.image.alt}
          />
        )}
      </Background>
    </Container>
  );
}

SliceSingleImage.propTypes = {
  slice: PropTypes.shape({
    image: PropTypes.object.isRequired,
  }),
};

