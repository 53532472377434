import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Margin = styled.div`
  margin: 0 1rem;

  > h3 {
    @media (${props => props.theme.breakpoints.lg}) {
      margin-bottom: 3rem;
    }
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    border-bottom: 1px solid ${(props) => props.theme.colours.navy};
    margin: 0 4rem;
    padding: 6rem 0;
  }
`;

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  column-gap: 1px;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    > div:nth-last-child(-n + 2) div {
      border: none;
    }

    > div:nth-child(odd) {
      border-right: 1px solid ${(props) => props.theme.colours.navy};
    }

    > div:nth-child(even) {
      margin-left: 4rem;
    }

    > div:nth-child(odd) {
      padding-right: 4rem;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  padding: 2rem 0;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};

  > div {
    width: 100%;
    justify-self: center;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 2fr;
    height: 100%;
    border: none;
    > div:first-child {
      width: 100%;
    }
  }
`;

const Inner = styled.div`
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 2rem;
  }
`;

export default function SliceProductCatalogue({ slice, items }) {
  return (
    <Container>
      <Margin>
        <h3>{slice.title.text}</h3>
        <Holder>
          {items.map((item, i) => (
            <div key={i}>
              <Content>
                <div>
                  <GatsbyImage
                    className="box_shadow"
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt}
                  />
                </div>
                <Inner>
                  <h3 className="CircularLight">{item.title.text}</h3>
                  <OutboundLink
                    id={"outbound-link-" + i}
                    className="button large outline"
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={item.button.url}>
                    {item.button_display_text.text}
                  </OutboundLink>
                </Inner>
              </Content>
            </div>
          ))}
        </Holder>
      </Margin>
    </Container>
  );
}

SliceProductCatalogue.propTypes = {
  slice: PropTypes.shape({
    title: PropTypes.object,
  }),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};
