import React from "react";
import styled from "styled-components";
import RichText from "prismic-reactjs/src/Component";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import TwoColumn from "../molecules/TwoColumn";

const Holder = styled.div`
  padding-top: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding-top: 6rem;
  }

  h3 {
    @media (${(props) => props.theme.breakpoints.lg}) {
      position: relative;
      margin: 2rem 0;
    }
  }
`;

const Inner = styled.span`
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 1rem 0;
    p {
      margin: 0;
    }
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: auto;
  padding: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 0 4rem;
  }
`;

const ContentHolder = styled.div`
  position: relative;

  > div {
    display: grid;
    grid-template-columns: auto 1.5fr;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }

  li {
    margin: 0;
  }

  > * {
    @media (${(props) => props.theme.breakpoints.lg}) {
      width: 50%;
      margin: 0;
    }
  }
`;

export default function SliceTwoColMediaLinksText({ slice }) {
  return (
    <Container>
      <Holder>
        <ImageHolder>
          <GatsbyImage
            image={slice.image.gatsbyImageData}
            alt={slice.image.alt || "Gatsby image"}
          />
        </ImageHolder>
        <TwoColumn>
          <ContentHolder>
            <div className="line">
              <h4>{slice.title.text}</h4>
              {slice.optional_icon.gatsbyImageData && (
                <GatsbyImage
                  image={slice.optional_icon.gatsbyImageData}
                  alt={slice.optional_icon.alt || "Gatsby image"}
                />
              )}
            </div>
          </ContentHolder>

          <Inner>
            {slice.description.richText && (
              <RichText render={slice.description.richText} />
            )}
            {slice.column_text_1.richText && (
              <Columns>
                <RichText render={slice.column_text_1.richText} />
                <RichText render={slice.column_text_2.richText} />
              </Columns>
            )}
            {slice.button.slug && (
              <Link
                className="button outline large"
                to={"/" + slice.button.slug}
              >
                {slice.button_display_text.text}
              </Link>
            )}
          </Inner>
        </TwoColumn>
      </Holder>
    </Container>
  );
}

SliceTwoColMediaLinksText.propTypes = {
  slice: PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    button: PropTypes.object,
    button_display_text: PropTypes.object,
    border_colour: PropTypes.oneOf(["navy", "white"]),
  }),
};

SliceTwoColMediaLinksText.defaultProps = {
  border_colour: "navy",
};

