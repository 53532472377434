import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";

import Container from "../atoms/Container";
import TwoColumn from "../molecules/TwoColumn";
import Accordion from "../atoms/Accordion";
import AccordionGroup from "../atoms/AccordionGroup";

const ImageHolder = styled.div`
  padding: 0;
  width: 100%;
  height: auto;

  * {
    min-height: 500px;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 0 0 2rem 0;
    * {
      max-height: 750px;
    }
  }
`;

const ContentHolder = styled.div`
  padding-bottom: 2rem;

  > h3 {
    margin-top: 0;
  }
`;

const List = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;

  > :first-child {
    border-top: 1px solid ${(props) => props.theme.colours.navy};
  }

  li {
    margin: 0;
  }
`;

export default function SliceHomeDivisions({ slice, links }) {
  return (
    <Container>
      <ImageHolder>
        <GatsbyImage
          image={slice.image.gatsbyImageData}
          alt={slice.image.alt}
        />
      </ImageHolder>

      <TwoColumn>
        <ContentHolder>
          <h4>{slice.title.text}</h4>
          <RichText render={slice.desc.richText} />
          {slice.button_display_text.text && (
            <Link
              className={slice.button_styling + " button large"}
              to={"/" + slice.button.slug}
            >
              {slice.button_display_text.text}
            </Link>
          )}
        </ContentHolder>
        <List>
          <AccordionGroup>
            {links.map((link, i) => (
              <Accordion key={i} title={link.link_display_text.text}>
                <RichText render={link.content.richText} />
              </Accordion>
            ))}
          </AccordionGroup>
        </List>
      </TwoColumn>
    </Container>
  );
}

SliceHomeDivisions.propTypes = {
  slice: PropTypes.shape({
    image: PropTypes.object,
    title: PropTypes.object,
    desc: PropTypes.object,
    button: PropTypes.object,
    button_display_text: PropTypes.object,
    button_styling: PropTypes.oneOf(["inverse", "outline"]),
  }),
  links: PropTypes.arrayOf(PropTypes.object),
};

SliceHomeDivisions.defaultProps = {
  button_styling: "outline",
};

