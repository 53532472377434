import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";

const Background = styled.div`
  background-color: ${(props) => props.theme.colours[props.bg_colour]};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export default function SliceSingleTitle({ slice }) {
  return (
    <Container>
      <Background bg_colour={slice.bg_colour.toLowerCase()}>
        {slice.title.text && <h4>{slice.title.text}</h4>}
        {slice.image.gatsbyImageData && (
          <GatsbyImage
            image={slice.image.gatsbyImageData}
            alt={slice.image.alt}
          />
        )}
      </Background>
    </Container>
  );
}

SliceSingleTitle.propTypes = {
  slice: PropTypes.shape({
    bg_colour: PropTypes.string.isRequired,
    title: PropTypes.object.isRequired,
    image: PropTypes.object.isRequired,
    border_colour: PropTypes.oneOf(["navy", "white"]),
  }),
};

SliceSingleTitle.defaultProps = {
  bg_colour: "navy",
};