import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/atoms/Seo"
import VideoHero from "../components/molecules/VideoHero"
import SliceWelcome from "../components/organisms/SliceWelcome"
import SliceTwoColTextButton from "../components/organisms/SliceTwoColTextButton"
import SliceTwoColLinks from "../components/organisms/SliceTwoColLinks"
import SliceImageTextRows from "../components/organisms/SliceImageTextRows"
import SliceFourColImage from "../components/organisms/SliceFourColImage"
import SliceTwoColImageText from "../components/organisms/SliceTwoColImageText"
import SliceProductCatalogue from "../components/organisms/SliceProductCatalogue"
import SliceTeamGrid from "../components/organisms/SliceTeamGrid"
import SliceThreeColText from "../components/organisms/SliceThreeColText"
import SliceTwoColMediaLinksText from "../components/organisms/SliceTwoColMediaLinksText"
import SliceSingleTitle from "../components/organisms/SliceSingleTitle"
import SliceTwoColImageTextButton from "../components/organisms/SliceTwoColTextImageButton"
import SliceTitleHero from "../components/organisms/SliceTitleHero"
import SliceDivisionBanner from "../components/organisms/SliceDivisionBanner"
import SliceTwoColImageTextLinks from "../components/organisms/SliceTwoColImageTextLinks"
import SliceCardText from "../components/organisms/SliceCardText"
import SliceCard from "../components/organisms/SliceCard"
import SliceSingleImage from "../components/organisms/SliceSingleImage"
import SliceHomeDivisions from "../components/organisms/SliceHomeDivisions"
import SliceMiscBars from "../components/organisms/SliceMiscBars"
import FormNewsletter from "../components/molecules/FormNewsletter"
import FormCareers from "../components/molecules/FormCareers"
import SliceNewsPreview from "../components/organisms/SliceNewsPreview"
import SliceVideoEmbed from "../components/organisms/SliceVideoEmbed"
import SlicePillars from "../components/organisms/SlicePillars"
import SliceUNSDGoals from "../components/organisms/SliceUNSDGoals"
import SliceConsciousCards from "../components/organisms/SliceConsciousCards"

function Page({ data }) {
  const page = data.prismicPage.data

  return (
    <>
      <Seo title={page.page_title.text} />

      {page.body.map((slice) => {
        if (slice.slice_type === "hero") {
          return (
            <VideoHero
              key={slice.id}
              slice={slice.primary}
              images={slice.items}
            />
          )
        } else if (slice.slice_type === "title_and_hero_image") {
          return (
            <SliceTitleHero
              key={slice.id}
              slice={slice.primary}
              logos={slice.items}
            />
          )
        } else if (slice.slice_type === "news_preview") {
          return <SliceNewsPreview key={slice.id} posts={slice.items} />
        } else if (slice.slice_type === "welcome") {
          return <SliceWelcome key={slice.id} slice={slice.primary} />
        } else if (slice.slice_type === "image_text_rows") {
          return (
            <SliceImageTextRows
              key={slice.id}
              slice={slice.primary}
              rows={slice.items}
            />
          )
        } else if (slice.slice_type === "two_col_text_image_button") {
          return (
            <SliceTwoColImageTextButton
              key={slice.id}
              slice={slice.primary}
              rows={slice.items}
            />
          )
        } else if (slice.slice_type === "product_catalogue") {
          return (
            <SliceProductCatalogue
              key={slice.id}
              slice={slice.primary}
              items={slice.items}
            />
          )
        } else if (slice.slice_type === "two_col_text_button") {
          return (
            <SliceTwoColTextButton
              key={slice.id}
              slice={slice.primary}
              font_colour={slice.primary.font_colour}
              bg_colour={slice.primary.bg_colour}
            />
          )
        } else if (slice.slice_type === "two_col_links") {
          return (
            <SliceTwoColLinks
              key={slice.id}
              slice={slice.primary}
              links={slice.items}
            />
          )
        } else if (slice.slice_type === "two_col_image_text") {
          return (
            <SliceTwoColImageText
              key={slice.id}
              slice={slice.primary}
              files={slice.items}
            />
          )
        } else if (slice.slice_type === "four_col_image_") {
          return (
            <SliceFourColImage
              key={slice.id}
              slice={slice.primary}
              cols={slice.items}
            />
          )
        } else if (slice.slice_type === "team_grid") {
          return (
            <SliceTeamGrid
              key={slice.id}
              slice={slice.primary}
              persons={slice.items}
            />
          )
        } else if (slice.slice_type === "three_col_text") {
          return (
            <SliceThreeColText
              key={slice.id}
              slice={slice.primary}
              stats={slice.items}
            />
          )
        } else if (slice.slice_type === "single_title") {
          return (
            <SliceSingleTitle
              key={slice.id}
              slice={slice.primary}
              bg_colour={slice.primary.bg_colour}
            />
          )
        } else if (slice.slice_type === "division_banner") {
          return (
            <SliceDivisionBanner
              key={slice.id}
              slice={slice.primary}
              bg_colour={slice.primary.bg_colour}
              font_colour={slice.primary.font_colour}
              row_colour={slice.primary.row_colour}
            />
          )
        } else if (slice.slice_type === "two_col_image_text_links") {
          return (
            <SliceTwoColImageTextLinks
              key={slice.id}
              slice={slice.primary}
              links={slice.items}
            />
          )
        } else if (slice.slice_type === "card_and_text") {
          return (
            <SliceCardText
              key={slice.id}
              slice={slice.primary}
              cards={slice.items}
            />
          )
        } else if (slice.slice_type === "three_cards") {
          return <SliceCard key={slice.id} slice={slice.primary} />
        } else if (slice.slice_type === "home_divisions") {
          return (
            <SliceHomeDivisions
              key={slice.id}
              slice={slice.primary}
              links={slice.items}
            />
          )
        } else if (slice.slice_type === "miscbars") {
          return (
            <SliceMiscBars
              key={slice.id}
              slice={slice.primary}
              items={slice.items}
            />
          )
        } else if (slice.slice_type === "single_image") {
          return <SliceSingleImage key={slice.id} slice={slice.primary} />
        } else if (slice.slice_type === "two_col_media_links_text_") {
          return (
            <SliceTwoColMediaLinksText key={slice.id} slice={slice.primary} />
          )
        } else if (slice.slice_type === "video_embed") {
          return <SliceVideoEmbed slice={slice.primary} key={slice.id} />
        } else if (slice.slice_type === "pillars") {
          return <SlicePillars pillars={slice.items} key={slice.id} />
        } else if (slice.slice_type === "unsd_goals") {
          return <SliceUNSDGoals items={slice.items} key={slice.id} />
        } else if (slice.slice_type === "simba_conscious_card") {
          return <SliceConsciousCards items={slice.items} primary={slice.primary} key={slice.id} />
        } else if (slice.slice_type === "newsletter") {
          return <FormNewsletter key={slice.id} />
        } else if (slice.slice_type === "careers") {
          return <FormCareers key={slice.id} />
        }
        return null
      })}
    </>
  )
}

export default Page

export const query = graphql`
  query ($id: String!) {
    prismicPage(id: { eq: $id }) {
      data {
        page_title {
          text
        }
        body {
          ... on PrismicPageDataBodyWelcome {
            id
            slice_type
            primary {
              title {
                text
              }
              hero_image {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              image_headline {
                gatsbyImageData
                alt
              }
              description_headline {
                text
              }
              description {
                richText
              }
              border_colour
            }
          }
          ... on PrismicPageDataBodyNewsPreview {
            id
            slice_type
            items {
              post {
                uid
                document {
                  ... on PrismicPost {
                    id
                    data {
                      title {
                        text
                      }
                      preview_description {
                        richText
                      }
                      preview_image {
                        gatsbyImageData
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyTwoColTextImageButton {
            id
            slice_type
            items {
              title {
                text
              }
              button_display_text {
                text
              }
              button {
                slug
                uid
                target
                url
              }
              image {
                gatsbyImageData
                alt
              }
              image_2 {
                gatsbyImageData
                alt
              }
              image_3 {
                gatsbyImageData
                alt
              }
              border_colour
            }
          }
          ... on PrismicPageDataBodyTwoColTextButton {
            id
            slice_type
            primary {
              bg_colour
              font_colour
              button_styling
              title {
                text
              }
              description {
                richText
              }
              button_display_text {
                text
              }
              button {
                slug
                uid
                target
                raw
              }
            }
          }
          ... on PrismicPageDataBodyTwoColMediaLinksText {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
              title {
                text
              }
              optional_icon {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FIXED
                  height: 100
                )
                alt
              }
              description {
                richText
              }
              column_text_1 {
                richText
              }
              column_text_2 {
                richText
              }
              button_display_text {
                text
              }
              button {
                slug
                uid
                target
              }
              border_colour
            }
          }
          ... on PrismicPageDataBodyVideoEmbed {
            id
            slice_type
            primary {
              title {
                text
              }
              description {
                richText
              }
              optional_thumbnail {
                gatsbyImageData
                alt
              }
              video {
                thumbnail_url
                embed_url
                title
                html
              }
              button_display_text {
                text
              }
              border_colour
            }
          }
          ... on PrismicPageDataBodyTwoColLinks {
            id
            slice_type
            items {
              link_display_text {
                text
              }
            }
          }
          ... on PrismicPageDataBodyTwoColImageTextLinks {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
              image2 {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
              image3 {
                gatsbyImageData(layout: FULL_WIDTH)
                alt
              }
              title {
                text
              }
              border_colour
            }
            items {
              link_display_text {
                text
              }
              link {
                slug
                uid
                link_type
                id
              }
            }
          }
          ... on PrismicPageDataBodyTwoColImageText {
            id
            slice_type
            items {
              image_1 {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              image_2 {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                alt
              }
              text_1 {
                richText
              }
              text_2 {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyTitleAndHeroImage {
            id
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              hero_image {
                alt
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 75
                  placeholder: BLURRED
                )
              }
            }
          }
          ... on PrismicPageDataBodyTeamGrid {
            id
            slice_type
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              name {
                text
              }
              role {
                text
              }
              linkedin {
                url
                slug
                uid
                link_type
              }
            }
          }
          ... on PrismicPageDataBodyProductCatalogue {
            id
            slice_type
            primary {
              title {
                text
              }
            }
            items {
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              title {
                text
              }
              button_display_text {
                text
              }
              button {
                slug
                uid
                url
                target
                id
              }
            }
          }
          ... on PrismicPageDataBodyThreeColText {
            id
            slice_type
            primary {
              column_1 {
                text
              }
              column_image {
                alt
                gatsbyImageData
              }
              column_2 {
                richText
              }
              column_3 {
                richText
              }
              partnership_logo {
                alt
                gatsbyImageData
              }
              partnership_title {
                text
              }
              show_stats
              two_symbol
              kinfo {
                text
              }
              kinfo2 {
                text
              }
              border_colour
            }
            items {
              percentage {
                text
              }
              percentage_topic {
                text
              }
            }
          }
          ... on PrismicPageDataBodySingleTitle {
            id
            slice_type
            primary {
              title {
                text
              }
              image {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 80
                  placeholder: BLURRED
                )
                alt
              }
              bg_colour
            }
          }
          ... on PrismicPageDataBodyDivisionBanner {
            id
            primary {
              bg_colour
              font_colour
              row_colour
              headline {
                text
              }
              headline_description {
                text
              }
              hero_image {
                alt
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
              logo {
                alt
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
              first_row_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              first_image_padded_width
              first_row_text {
                text
              }
              second_row_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              second_row_headline {
                text
              }
              second_row_description {
                richText
              }
              third_row_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              third_image_padded_width
              third_row_text {
                text
              }
              fourth_row_image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              fourth_row_headline {
                text
              }
              fourth_row_description {
                richText
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyHero {
            id
            slice_type
            primary {
              description {
                text
              }
              about {
                richText
              }
              title {
                text
              }
              video_hero_title {
                richText
              }
              video_hero_sub_heading {
                richText
              }
              internal_video {
                id
                slug
                url
                type
              }
              internal_video2 {
                id
                slug
                url
                type
              }
            }
            items {
              about_image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyImageTextRows {
            id
            slice_type
            primary {
              image_side
            }
            items {
              border_colour
              button_display_text {
                text
              }
              button {
                slug
                uid
                target
                id
              }
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyFourColImage {
            id
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 240
                )
              }
            }
          }
          ... on PrismicPageDataBodyThreeCards {
            id
            slice_type
            primary {
              color_1
              font_color_1
              color_2
              font_color_2
              color_3
              font_color_3
              first_symbol {
                text
              }
              second_symbol {
                text
              }
              icon_1 {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              title_1 {
                richText
              }
              desc_1 {
                richText
              }
              icon_2 {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              title_2 {
                richText
              }
              desc_2 {
                richText
              }
              icon_3 {
                alt
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              }
              title_3 {
                richText
              }
              desc_3 {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyCardAndText {
            id
            slice_type
            items {
              color
              font_color
              title {
                richText
              }
              icon {
                alt
                gatsbyImageData(
                  height: 50
                  placeholder: BLURRED
                  layout: CONSTRAINED
                )
              }
              desc {
                richText
              }
            }
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicPageDataBodySingleImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyHomeDivisions {
            id
            slice_type
            primary {
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
              title {
                text
              }
              desc {
                richText
              }
              button_display_text {
                text
              }
              button_styling
              button {
                slug
                link_type
                uid
              }
            }
            items {
              link_display_text {
                text
              }
              content {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyMiscbars {
            id
            slice_type
            primary {
              title {
                text
              }
              column1 {
                richText
              }
            }
            items {
              border_colour
              flextitle {
                text
              }
              country {
                gatsbyImageData(
                  layout: FIXED
                  placeholder: BLURRED
                  height: 100
                )
                alt
              }
              image1 {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              image2 {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              desc {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyNewsletter {
            id
            slice_type
            primary {
              newsletter
            }
          }
          ... on PrismicPageDataBodyCareers {
            id
            slice_type
            primary {
              careers
            }
          }
          ... on PrismicPageDataBodyPillars {
            id
            slice_type
            items {
              title {
                richText
              }
              link {
                uid
                slug
              }
              icon_1 {
                gatsbyImageData
                alt
              }
              icon_2 {
                gatsbyImageData
                alt
              }
              icon_3 {
                gatsbyImageData
                alt
              }
            }
          }
          ... on PrismicPageDataBodyUnsdGoals {
            id
            slice_type
            items {
              number
            }
          }
          ... on PrismicPageDataBodySimbaConsciousCard {
            id
            slice_type
            primary {
              icon {
                gatsbyImageData
                alt
              }
              title {
                richText
                text
              }
              measurable_goal {
                richText
              }
            }
            items {
              card_type
              card_title {
                richText
                text
              }
              card_description {
                richText
              }
            }
          }
        }
      }
    }
  }
`
