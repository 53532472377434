import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import LinkedinLogo from "../../assets/images/simba-icon-linkedin.inline.svg";

const Holder = styled.div`
  padding: 2rem 0;
  margin: 0 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    background-color: ${(props) => props.theme.colours.white};
    column-gap: 4rem;
    row-gap: 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  padding: 1rem 0;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 2rem 0;
    margin: 0;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  &:hover {
    #linkedin {
      opacity: 1;
    }
  }

  #linkedin {
    cursor: pointer;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.25s all ease;
  }

  #logo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0.4rem;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > h4 {
    margin: 0;
  }

  > h6 {
    margin: 0;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    padding-left: 2rem;
  }
`;

export default function SliceTeamGrid({ persons }) {
  return (
    <Container>
      <Holder>
        <Grid>
          {persons.map((person, i) => (
            <Wrapper key={i}>
              <Content>
                <ContentInner>
                  <ImageWrapper>
                    <a id="linkedin" href={person.linkedin.url}>
                      <LinkedinLogo id="logo" className="LinkedinLogo" />
                    </a>
                    <GatsbyImage
                      imgStyle={{ objectFit: "contain" }}
                      image={person.image.gatsbyImageData}
                      alt={person.image.alt}
                    />
                  </ImageWrapper>
                  <Inner>
                    <h4 className="CircularBoldImportant">
                      {person.name.text}
                    </h4>
                    <h6>{person.role.text}</h6>
                  </Inner>
                </ContentInner>
              </Content>
            </Wrapper>
          ))}
        </Grid>
      </Holder>
    </Container>
  );
}

SliceTeamGrid.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.object).isRequired,
};

