import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Cross from "../../assets/images/iconCross.inline.svg";
import { CSSTransition } from "react-transition-group";
import useWindowSize from "../../hooks/useWindowSize";
import YoutubeEmbed from "./YoutubeEmbed";
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll";

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 3rem;
  }

  &.video-holder-appear,
  &.video-holder-enter {
    opacity: 0;

    video, .embed {
      opacity: 0;
    }
  }

  &.video-holder-appear-active,
  &.video-holder-appear-done,
  &.video-holder-enter-active,
  &.video-holder-enter-done {
    opacity: 1;
    transition: opacity 250ms;

    video, .embed {
      opacity: 1;
      transition: opacity 250ms 250ms;
    }
  }

  &.video-holder-exit {
    opacity: 1;

    video, .embed {
      opacity: 1;
    }
  }

  &.video-holder-exit-active {
    opacity: 0;
    transition: opacity 250ms 250ms;

    video, .embed {
      opacity: 0;
      transition: opacity 250ms;
    }
  }

  video, .embed {
    position: absolute;
    top: 50%;
    left: 2rem;
    width: calc(100% - 4rem);
    height: auto;
    transform: translateY(-50%);
    @media ( ${props => props.theme.breakpoints.md} ) {
      left: 3rem;
      width: calc(100% - 6rem);
    }
  }

  &.fullHeight {
    video, .embed {
      position: absolute;
      top: 2rem;
      left: 50%;
      height: calc(100vh - 4rem);
      width: auto;
      transform: translateX(-50%);
      @media ( ${props => props.theme.breakpoints.md} ) {
        top: 3rem;
        height: calc(100% - 6rem);
      }
    }

    .embed {
      width: calc((100vh - 4rem) / 9 * 16);
      @media ( ${props => props.theme.breakpoints.md} ) {
        width: calc((100vh - 6rem) / 9 * 16);
      }
    }
  }

  button {
    position: absolute;
    top: 1rem;
    right: 1rem;

    svg {
      path {
        stroke: ${props => props.theme.colours.navy};
      }
    }
  }
`;

function VideoOverlay({ video, embed, open, closeHandler }) {
  useDisableBodyScroll(open);
  const [videoPosition, setVideoPosition] = useState("fullWidth");
  const size = useWindowSize();

  useEffect(() => {
    const windowRatio = (size.width / size.height).toFixed(3);
    const videoRatio = (16 / 9).toFixed(3);
    if (windowRatio < videoRatio) {
      setVideoPosition("fullWidth");
    } else {
      setVideoPosition("fullHeight");
    }
  }, [size]);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={open}
      timeout={500}
      classNames="video-holder"
    >
      <Holder className={videoPosition}>
        <button style={{ margin: "0" }} className="button icon" aria-label="Close video lightbox"
                onClick={() => closeHandler()}><Cross />
        </button>
        {video && <video width="320" height="240" autoPlay controls>
          <source src={video.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>}
        {embed && <div className="embed">
          <YoutubeEmbed>
            <div dangerouslySetInnerHTML={{ __html: embed.html }} />
          </YoutubeEmbed>
        </div>}
      </Holder>
    </CSSTransition>
  );
}

VideoOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
  video: PropTypes.object,
  embed: PropTypes.object,
  closeHandler: PropTypes.func.isRequired,
};

export default VideoOverlay;