import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";
import Container from "../atoms/Container";

const Holder = styled.div`
  margin: 0 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
  }
`;

const Grid = styled.div`
  display: grid;
  padding: 2rem 0;
  row-gap: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 4rem 0;
    row-gap: 4rem;
  }
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 4rem;
  }

  .column-two,
  .column-three {
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 0;
    }
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: auto;
`;

const TextHolder = styled.div`
  > :first-child {
    margin-top: 0;
  }
`;

export default function SliceTwoColImageText({ files }) {
  const { tl, holderRef, gsap, st, q } = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = st.matchMedia({
        "(min-width: 576px)": function() {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: holderRef.current,
                start: "top 90%",
                end: "bottom bottom",
                scrub: 0.25,
              },
            })
            .from(q(`.column-one`), {
              y: 50,
              autoAlpha: 0,
              duration: 1,
            })
            .from(q(`.column-two`), {
                y: 150,
                autoAlpha: 0,
                duration: 2,
              },
              0,
            );
        },
      });
    }
  });

  return (
    <Container>
      <Holder ref={holderRef}>
        {files.map((file, i) => (
          <Grid key={i}>
            {file.image_1.gatsbyImageData && (
              <Column className="column-one">
                <ImageHolder>
                  <GatsbyImage
                    image={file.image_1.gatsbyImageData}
                    alt={file.image_1.alt}
                    imgStyle={{ borderRadius: "4px" }}
                  />
                </ImageHolder>
                <TextHolder>
                  <RichText render={file.text_1.richText} />
                </TextHolder>
              </Column>
            )}
            {file.image_2.gatsbyImageData && (
              <Column className="column-two">
                <TextHolder>
                  <RichText render={file.text_2.richText} />
                </TextHolder>
                <ImageHolder>
                  <GatsbyImage
                    image={file.image_2.gatsbyImageData}
                    alt={file.image_2.alt}
                    imgStyle={{ borderRadius: "4px" }}
                  />
                </ImageHolder>
              </Column>
            )}
          </Grid>
        ))}
      </Holder>
    </Container>
  );
}

SliceTwoColImageText.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
};

