import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import RichText from "prismic-reactjs/src/Component";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";
import ThreeColumn from "../molecules/ThreeColumn";

const Holder = styled.div`
  margin: 0 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding-bottom: 6rem;
    margin: 0 4rem;
  }
`;

const ColumnHolder = styled.span`
  margin: 1rem 0;

  p:first-child {
    margin-top: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  > div:nth-child(4) > div {
    border: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 1px;
    background-color: ${(props) => props.theme.colours.navy};
    > div:nth-child(4) > div {
      border: none;
    }

    > div:nth-child(3) > div {
      border: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colours.white};
`;

const Inner = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colours[props.border_colour]};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 2rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const TitleMap = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  > h5 {
    margin: 0;
  }
`;

const ImageHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto;
`;

const Caption = styled.p`
  width: 100%;
  text-align: left;
  margin: 0.5rem 0;
`;

export default function SliceMiscBars({ slice, items }) {
  return (
    <Container>
      <Holder>
        <ThreeColumn>
          <h4 className="CircularBold">{slice.title.text}</h4>
          {slice.column1.richText && (
            <ColumnHolder>
              <RichText render={slice.column1.richText} />
            </ColumnHolder>
          )}
        </ThreeColumn>
        <Grid>
          {items.map((item, i) => (
            <Wrapper key={i}>
              <Inner border_colour={item.border_colour.toLowerCase()}>
                <TitleMap id={item.country.gatsbyImageData ? "bars" : ""}>
                  <h5 className="CircularBoldInverse">{item.flextitle.text}</h5>
                  {item.country.gatsbyImageData && (
                    <GatsbyImage
                      image={item.country.gatsbyImageData}
                      alt={item.country.alt}
                    />
                  )}
                </TitleMap>
                <FlexContainer>
                  <ImageHolder>
                    <GatsbyImage
                      image={item.image1.gatsbyImageData}
                      alt={item.image1.alt}
                    />
                    {item.image2.gatsbyImageData && (
                      <Caption>{item.image1.alt}</Caption>
                    )}
                  </ImageHolder>

                  {item.image2.gatsbyImageData && (
                    <ImageHolder>
                      <GatsbyImage
                        image={item.image2.gatsbyImageData}
                        alt={item.image2.alt}
                      />
                      <Caption>{item.image2.alt}</Caption>
                    </ImageHolder>
                  )}
                </FlexContainer>
                <RichText render={item.desc.richText} />
              </Inner>
            </Wrapper>
          ))}
        </Grid>
      </Holder>
    </Container>
  );
}

SliceMiscBars.propTypes = {
  slice: PropTypes.shape({
    title: PropTypes.object.isRequired,
    column1: PropTypes.object,
    column2: PropTypes.object,
    items: PropTypes.array,
    border_colour: PropTypes.oneOf(["navy", "white"]),
  }),
};

SliceMiscBars.defaultProps = {
  border_colour: "navy",
};

