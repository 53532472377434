import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import Container from "../atoms/Container";
import Arrow from "../../../static/up-arrow.inline.svg";

const Holder = styled.div`
  margin: 0 1rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  border-bottom: 1px solid ${(props) => props.theme.colours[props.border_colour]};
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 4rem 0;
  }
`;

const TwoColMarginless = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 4rem;
  }
`;

const Inner = styled.ul`
  display: block;
  list-style: none;
  padding: 0;

  > :first-child {
    border-top: 1px solid ${(props) => props.theme.colours.navy};
  }

  li {
    margin: 0;

    a {
      display: grid;
      grid-template-columns: 1fr 1.5rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid ${(props) => props.theme.colours.navy};
      margin: 0;
      padding: 1rem 0rem;
      transition: background-color 250ms ease-in-out;

      &:hover {
        background-color: ${(props) => props.theme.colours.grey};

        > svg {
          transform: rotate(90deg);
        }
      }

      svg {
        height: 1.5rem;
        width: auto;
        transform: rotate(45deg);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  h4 {
    margin: 0;
  }
`;

const ImageHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
`;

export default function SliceTwoColImageTextLinks({ slice, links }) {
  return (
    <Container>
      <Holder border_colour={slice.border_colour.toLowerCase()}>
        {slice.image.gatsbyImageData && (
          <ImageHolder>
            <GatsbyImage
              image={slice.image.gatsbyImageData}
              alt={slice.image.alt}
            />
            <GatsbyImage
              image={slice.image2.gatsbyImageData}
              alt={slice.image2.alt}
            />
            <GatsbyImage
              image={slice.image3.gatsbyImageData}
              alt={slice.image3.alt}
            />
          </ImageHolder>
        )}
        <TwoColMarginless>
          <h4 className="CircularBold">{slice.title.text}</h4>
          <Inner>
            {links.map((link, i) => (
              <li key={i}>
                <Link to={"/" + link.link.uid}>
                  <h4>{link.link_display_text.text}</h4>
                  <Arrow />
                </Link>
              </li>
            ))}
          </Inner>
        </TwoColMarginless>
      </Holder>
    </Container>
  );
}

SliceTwoColImageTextLinks.propTypes = {
  slice: PropTypes.shape({
    image: PropTypes.object,
    image2: PropTypes.object,
    image3: PropTypes.object,
    title: PropTypes.object,
    border_colour: PropTypes.oneOf(["navy", "white"]),
  }),
  links: PropTypes.arrayOf(PropTypes.object),
};

SliceTwoColImageTextLinks.defaultProps = {
  border_colour: "navy",
};