import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import RichText from "prismic-reactjs/src/Component";
import Container from "../atoms/Container";

const Holder = styled.div`
  color: ${(props) => props.theme.colours.navy};
  background-color: ${(props) => props.theme.colours.white};
`;

const Grid = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colours[props.border_colour]};
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: calc(50% - 2rem) 1fr 1fr;
    column-gap: 4rem;
    margin: 0 4rem;
    padding: 4rem 0;
    p {
      margin-top: 0;
    }
  }
`;

const TextWrapper = styled.div`
  padding: 2rem 0;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 0;
  }

  h4 {
    margin: 0;
  }
`;

export default function SliceImageTextRows({ rows }) {
  const { tl, holderRef, gsap, st, q } = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = st.matchMedia({
        // desktop
        "(min-width: 576px)": function() {
          gsap.timeline({
            scrollTrigger: {
              trigger: holderRef.current,
              start: "top 90%",
              end: "bottom bottom",
              scrub: 0.25,
            },
          }).from(q(`.image-text-row`), {
            yPercent: 50,
            autoAlpha: 0,
            duration: 1,
            stagger: 0.5,
            ease: "power1.out",
          });
        },
        // Add more breakpoints if needed.
        // See https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.matchMedia()
      });
    }
  });

  return (
    <Container>
      <Holder ref={holderRef}>
        {rows.map((row, i) => (
          <Grid key={i} border_colour={row.border_colour.toLowerCase()} className="image-text-row">
            <GatsbyImage
              imgStyle={{ borderRadius: "4px" }}
              image={row.image.gatsbyImageData}
              alt={row.image.alt}
            />
            <TextWrapper>
              <h4>{row.title.text}</h4>
            </TextWrapper>
            <div>
              <RichText render={row.description.richText} />
              {row.button_display_text.text && (
                <Link
                  className="button outline large"
                  to={"/" + row.button.slug}
                >
                  {row.button_display_text.text}
                </Link>
              )}
            </div>
          </Grid>
        ))}
      </Holder>
    </Container>
  );
}

SliceImageTextRows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};