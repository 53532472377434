import React from "react"
import Container from "../atoms/Container"
import PropTypes from "prop-types"
import Pillar from "../molecules/Pillar"

SlicePillars.propTypes = {
  pillars: PropTypes.arrayOf(PropTypes.object).isRequired,
}

function SlicePillars({ pillars }) {
  return (
    <Container>
      {pillars.map((pillar, index) => {
        return <Pillar key={index} title={pillar.title} iconOne={pillar.icon_1} iconTwo={pillar.icon_2}
                       iconThree={pillar.icon_3} link={pillar.link} />
      })}
    </Container>
  )
}

export default SlicePillars