import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import Container from "../atoms/Container";

const Holder = styled.div`
  color: ${(props) => props.theme.colours.navy};
  background-color: ${(props) => props.theme.colours.white};
`;

const Grid = styled.li`
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 2rem 0;
  gap: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colours[props.border_colour]};
  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    margin: 0 4rem;
    padding: 6rem 0;
    p {
      margin-top: 0;
    }
  }
`;

const ImageHolder = styled.div`
  display: flex;
  grid-gap: 1rem;
`;

const TextWrapper = styled.div`
  h4 {
    margin-top: 0;
  }
`;

export default function SliceTwoColImageTextButton({ rows }) {
  const { tl, holderRef, gsap, st, q } = useScrollTrigger();

  useEffect(() => {
    if (!tl.current) {
      tl.current = st.matchMedia({
        // desktop
        "(min-width: 576px)": function() {
          gsap.timeline({
            scrollTrigger: {
              trigger: holderRef.current,
              start: "top 90%",
              end: "bottom bottom",
              scrub: 0.15,
            },
          }).from(q(`.text-image-button`), {
            yPercent: 50,
            autoAlpha: 0,
            duration: 1,
            stagger: 0.5,
            ease: "power1.out",
          });
        },
        // Add more breakpoints if needed.
        // See https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.matchMedia()
      });
    }
  });

  return (
    <Container>
      <div ref={holderRef}>
        {rows.map((row, i) => (
          <Holder key={i} className="text-image-button">
            <Grid border_colour={row.border_colour.toLowerCase()}>
              <ImageHolder>
                <GatsbyImage
                  className="box_shadow"
                  alt={row.image.alt || "Gatsby image"}
                  image={row.image.gatsbyImageData}
                />
                {row.image_2.gatsbyImageData && (
                  <GatsbyImage
                    className="box_shadow"
                    alt={row.image_2.alt || "Gatsby image"}
                    image={row.image_2.gatsbyImageData}
                  />
                )}
                {row.image_3.gatsbyImageData && (
                  <GatsbyImage
                    className="box_shadow"
                    alt={row.image_3.alt || "Gatsby image"}
                    image={row.image_3.gatsbyImageData}
                  />
                )}
              </ImageHolder>
              <TextWrapper>
                <h4 className="CircularLight">{row.title.text}</h4>
                {row.button.slug ? (
                  <a
                    className="button outline large"
                    href={"/" + row.button.slug}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {row.button_display_text.text}
                  </a>
                ) : (
                  <a
                    className="button large outline"
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={row.button.url}
                  >
                    {row.button_display_text.text}
                  </a>
                )}
              </TextWrapper>
            </Grid>
          </Holder>
        ))}
      </div>
    </Container>
  );
}

SliceTwoColImageTextButton.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

