import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";

import Container from "../atoms/Container";

const Holder = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  margin: 0 1rem;
  padding: 2rem 0;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 6rem 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
    gap: 2rem;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (${(props) => props.theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 3fr 0.5fr 3fr 0.5fr 3fr;
    gap: 2rem;
    h3 {
      text-align: center;
    }
  }
`;

const Cards = styled.div`
  background-color: ${(props) => props.theme.colours[props.color]};
  color: ${(props) => props.theme.colours[props.font_color]};

  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem 2rem;
  text-align: center;
  height: 13rem;
  width: 100%;
`;

export default function SliceCard({ slice }) {
  return (
    <Container>
      <Holder>
        <Wrapper>
          <Inner>
            <Cards color={slice.color_1} font_color={slice.font_color_1}>
              {slice.title_1.richText && (
                <RichText render={slice.title_1.richText} />
              )}
              <GatsbyImage
                imgStyle={{ objectFit: "contain" }}
                image={slice.icon_1.gatsbyImageData}
                alt={slice.icon_1.alt}
              />
              <RichText render={slice.desc_1.richText} />
            </Cards>
            <h3>{slice.first_symbol.text}</h3>
            <Cards color={slice.color_2} font_color={slice.font_color_2}>
              {slice.title_2.richText && (
                <RichText render={slice.title_2.richText} />
              )}
              <GatsbyImage
                imgStyle={{ objectFit: "contain" }}
                image={slice.icon_2.gatsbyImageData}
                alt={slice.icon_2.alt}
              />
              <RichText render={slice.desc_2.richText} />
            </Cards>
            <h3>{slice.second_symbol.text}</h3>
            <Cards color={slice.color_3} font_color={slice.font_color_3}>
              {slice.title_3.richText && (
                <RichText render={slice.title_3.richText} />
              )}
              <GatsbyImage
                imgStyle={{ objectFit: "contain" }}
                image={slice.icon_3.gatsbyImageData}
                alt={slice.icon_3.alt}
              />
              <RichText render={slice.desc_3.richText} />
            </Cards>
          </Inner>
        </Wrapper>
      </Holder>
    </Container>
  );
}

SliceCard.propTypes = {
  slice: PropTypes.shape({
    color_1: PropTypes.bool,
    title_1: PropTypes.object,
    desc_1: PropTypes.object,
    icon_1: PropTypes.object.isRequired,
    color_2: PropTypes.bool,
    title_2: PropTypes.object,
    desc_2: PropTypes.object,
    icon_2: PropTypes.object.isRequired,
    color_3: PropTypes.bool,
    title_3: PropTypes.object,
    desc_3: PropTypes.object,
    icon_3: PropTypes.object.isRequired,
  }),
};

