import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import EmbedContainer from "../atoms/EmbedContainer";
import TwoColumn from "./TwoColumn";
import RichText from "prismic-reactjs/src/Component";
import useScrollTrigger from "../../hooks/useScrollTrigger";

const Holder = styled.div`
`;

const Inner = styled.div`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;


const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
  color: ${(props) => props.theme.colours.white};
  text-align: center;
  pointer-events: none;

  span {
    overflow: hidden;
    letter-spacing: -0.15rem;
    line-height: 90%;
  }

  .title-one, .title-two, .title-three {
    visibility: hidden;
  }

  .first-line {
    margin-bottom: -0.5rem;

    h1 {
      margin-bottom: 0;
    }

    h1:first-child {
      margin-right: 1.5rem;

    }

    h1 {
      display: inline-block;
    }
  }

  .second-line {
    h1 {
      margin-top: 0;
    }
  }
`;

const ColumnHolder = styled.div`
  color: ${(props) => props.theme.colours.white};
  background-color: ${(props) => props.theme.colours.navy};
  // padding: 2rem 0;
`;

const SubHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 4rem 0;
  }

  h4:first-child {
    margin-top: 1rem !important;
    margin-bottom: 0;
    font-family: "Circular Bold", Helvetica, Arial, sans-serif;
  }

  > * {
    width: 75%;
    text-align: center;
    margin-top: 0;
    @media (${(props) => props.theme.breakpoints.lg}) {
      width: 50%;
    }
  }
`;

function VideoHero({ slice }) {
  const { tl, holderRef, gsap, st, q } = useScrollTrigger();
  const textTimeline = gsap.timeline({ defaults: { autoAlpha: 1, duration: 0.8, ease: "power2.out" } });

  useEffect(() => {
    textTimeline.from(".title-one", {
      opacity: 0,
      autoAlpha: 0,
      yPercent: "100",
    })
      .from(".title-two", {
        delay: 0.2,
        yPercent: "100",
        opacity: 0,
        autoAlpha: 0,
      }, "<")
      .from(".title-three", {
        delay: 0.5,
        opacity: 0,
        autoAlpha: 0,
        yPercent: "100",
      }, "<");

    if (!tl.current) {
      tl.current = st.matchMedia({
        // desktop
        "(min-width: 300px)": function() {
          gsap.timeline({
            scrollTrigger: {
              trigger: holderRef.current,
              start: "top top",
              end: "bottom bottom",
              scrub: 0.15,
              markers: false,
            },
          }).to(q(`.move-left`), {
            xPercent: -30,
            duration: 5,
            ease: "power1.out",
          }).to(q(`.move-right`), {
            xPercent: 20,
            duration: 5,
            ease: "power1.out",
          }, "<").from(q(`.text`), {
            yPercent: 40,
            duration: 2,
            ease: "power1.out",
          }, "<");
        },
        // Add more breakpoints if needed.
        // See https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.matchMedia()
      });
    }
  });

  return (
    <Holder ref={holderRef}>
      <Inner>
        <TitleContainer>
          <span className="first-line move-right">
            <h1 className="title-one CircularLight">Making</h1>
            <h1 className="title-two CircularLight">better,</h1>
          </span>
          <span className="second-line move-left"><h1 className="title-three CircularLight">together.</h1></span>
        </TitleContainer>
        <EmbedContainer>
          <video
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: "10",
              aspectRatio: "16/9",
            }}
            autoPlay
            playsInline
            muted
            loop
          >
            <source
              src={slice.internal_video.url}
              title={slice.internal_video.slug}
              type="video/webm"
            />
            <source
              src={slice.internal_video2.url}
              title={slice.internal_video2.slug}
              type="video/mp4"
            />
          </video>
        </EmbedContainer>
      </Inner>
      <SubHero className="text">
        <RichText render={slice.video_hero_sub_heading.richText} />
      </SubHero>
      <ColumnHolder>
        <TwoColumn>
          <h4 className="CircularBold column-one">
            Expertise
          </h4>
          <span>
            <h4>
              Based on a long history of textile experience, we have built
              expertise across the full product lifecycle.
            </h4>
          </span>
        </TwoColumn>
      </ColumnHolder>
    </Holder>
  );
}

export default VideoHero;

VideoHero.propTypes = {
  slice: PropTypes.shape({
    internal_video: PropTypes.object,
    internal_video2: PropTypes.object,
  }),
};