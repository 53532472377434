import React from "react"
import GoalOne from "../../static/unsdg/unsdg-01.inline.svg"
import GoalTwo from "../../static/unsdg/unsdg-02.inline.svg"
import GoalThree from "../../static/unsdg/unsdg-03.inline.svg"
import GoalFour from "../../static/unsdg/unsdg-04.inline.svg"
import GoalFive from "../../static/unsdg/unsdg-05.inline.svg"
import GoalSix from "../../static/unsdg/unsdg-06.inline.svg"
import GoalSeven from "../../static/unsdg/unsdg-07.inline.svg"
import GoalEight from "../../static/unsdg/unsdg-08.inline.svg"
import GoalNine from "../../static/unsdg/unsdg-09.inline.svg"
import GoalTen from "../../static/unsdg/unsdg-10.inline.svg"
import GoalEleven from "../../static/unsdg/unsdg-11.inline.svg"
import GoalTwelve from "../../static/unsdg/unsdg-12.inline.svg"
import GoalThirteen from "../../static/unsdg/unsdg-13.inline.svg"
import GoalFourteen from "../../static/unsdg/unsdg-14.inline.svg"
import GoalFifteen from "../../static/unsdg/unsdg-15.inline.svg"
import GoalSixteen from "../../static/unsdg/unsdg-16.inline.svg"
import GoalSeventeen from "../../static/unsdg/unsdg-17.inline.svg"

export const goals = [
  <GoalOne />,
  <GoalTwo />,
  <GoalThree />,
  <GoalFour />,
  <GoalFive />,
  <GoalSix />,
  <GoalSeven />,
  <GoalEight />,
  <GoalNine />,
  <GoalTen />,
  <GoalEleven />,
  <GoalTwelve />,
  <GoalThirteen />,
  <GoalFourteen />,
  <GoalFifteen />,
  <GoalSixteen />,
  <GoalSeventeen />,
]