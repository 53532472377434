import React from "react"
import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"
import Microscope from "../../assets/images/microscope.inline.svg"
import Document from "../../assets/images/document.inline.svg"
import Lightbulb from "../../assets/images/lightbulb.inline.svg"
import Cog from "../../assets/images/cog.inline.svg"
import { PrismicRichText } from "@prismicio/react"

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const wiggle = keyframes`
  0% {
    transform: rotate(10deg) translateY(0%);
  }
  25% {
    transform: rotate(0deg) translateY(5%);
  }
  50% {
    transform: rotate(-10deg) translateY(0%);
  }
  75% {
    transform: rotate(0deg) translateY(5%);
  }
  100% {
    transform: rotate(10deg) translateY(0%);
  }
`

const Holder = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-rows: auto auto;
  flex-direction: ${props => props.name === "microscope" || props.name === "lightbulb" ? "column-reverse" : "column"};
  padding: 2rem;
  border-radius: 10px;
  background-color: ${props => props.theme.colours.grey};
  position: relative;
  min-height: 25vh;
  max-width: 20rem;
  min-width: 17.5rem;
  overflow: hidden;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: rgba(237, 235, 234, 0.75);
  }

  &:hover .card-icon {
    transform: ${props => props.name === "document" ? "scale(1.2)" : "scale(1.2) translateY(20%) rotate(-20deg)"};
    animation: ${props => props.name === "cog" ? rotate : props.name === "lightbulb" ? wiggle : null} ${props => props.name === "cog" ? "4s" : "1s"} linear infinite;
  }
`

const Inner = styled.div`
  text-align: center;
`

const IconHolder = styled.div`
  min-height: 150px;
  grid-row: ${props => props.name === "microscope" || props.name === "lightbulb" ? "1" : "2"};
  position: relative;

  svg {
    height: 100%;
    width: auto;
    overflow: visible;
    transform-origin: center center;
    transform: rotateZ(${props => props.rotate ? props.rotate + "deg" : "0deg"}) translateX(${props => props.position.left + "%"}) translateY(${props => props.position.bottom + "%"}) scale(${props => props.scale});
    transition: all 200ms ease-in-out;
  }

  .card-icon {
    transform-origin: center center;
    transition: all 200ms ease-in-out;
    height: 100%;
    width: auto;
  }
`

ConsciousCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  text: PropTypes.object.isRequired,
}

const state = [
  {
    name: "lightbulb",
    position: { left: 50, bottom: 0 },
    scale: 1,
  },
  {
    name: "cog",
    position: { left: 70, bottom: 20 },
    scale: 1.7,
  },
  {
    name: "microscope",
    position: { left: 40, bottom: -5 },
    scale: 1,
  },
  {
    name: "document",
    position: { left: 50, bottom: 25 },
    scale: 1.2,
    rotate: 5,
  },
]

function ConsciousCard({ type, title, text }) {
  const targetedType = state.filter(item => item.name === type)

  return (
    <Holder name={targetedType[0].name} rotate={targetedType[0].rotate}>
      <Inner>
        <h6 className="CircularBold">{title}</h6>
        <PrismicRichText field={text} />
      </Inner>
      <IconHolder name={targetedType[0].name} position={targetedType[0].position} rotate={targetedType[0].rotate}
                  scale={targetedType[0].scale}>
        {targetedType[0].name === "microscope" && <Microscope />}
        {targetedType[0].name === "document" && <Document />}
        {targetedType[0].name === "lightbulb" && <Lightbulb />}
        {targetedType[0].name === "cog" && <Cog />}
      </IconHolder>
    </Holder>
  )
}

export default ConsciousCard