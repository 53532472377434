import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import Container from "../atoms/Container";

const Holder = styled.div`
  display: flex;
  justify-content: center;
  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 2rem 4rem;
  }
`;

const Grid = styled.div`
  background-color: ${(props) => props.theme.colours.navy};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  column-gap: 1px;
  margin: 2rem 0rem;

  > div:nth-child(11) > div {
    border: none;
  }

  > div:nth-child(12) > div {
    border: none;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    > div:nth-child(10) > div {
      border: none;
    }
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    > div:nth-child(9) > div {
      border: none;
    }

    > div:nth-child(10) > div {
      border: none;
    }
  }
`;

const ImgHolder = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
    margin: 0 1rem;
    height: 100%;
    border-bottom: 1px solid ${(props) => props.theme.colours.navy};
  }
`;

export default function SliceFourColImage({ cols }) {
  return (
    <Container>
      <Holder id="Brands">
        <Grid>
          {cols.map((col, i) => (
            <ImgHolder key={i}>
              <div>
                <GatsbyImage
                  imgStyle={{ objectFit: "contain" }}
                  image={col.logo.gatsbyImageData}
                  alt={col.logo.alt}
                />
              </div>
            </ImgHolder>
          ))}
        </Grid>
      </Holder>
    </Container>
  );
}

SliceFourColImage.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
};
