import React, { useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

import Container from "../atoms/Container";
import TwoColumn from "./TwoColumn";

const Wrapper = styled.div`
  color: ${(props) => props.theme.colours.navy};
  background-color: ${(props) => props.theme.colours.white};
`;

const Holder = styled.div`
  form {
    display: flex;
    flex-direction: column;

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    input, textarea {
      width: 100%;
      margin: 0;
      padding: 0.25rem 0.5rem;
      ${(props) => props.theme.fluidType(-1)};
    }

    textarea {
      max-width: 40rem;
    }

    .fieldset,
    button {
      width: inherit;
    }

    .errorMsg {
      color: ${(props) => props.theme.colours.red};
      ${(props) => props.theme.fluidType(-1)};
      display: block;
      margin: 0.2rem 0;
    }
  }
`;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignUpSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email("Email is not valid")
    .max(255)
    .required("E-mail is required"),
  firstName: Yup.string().required("Write your first name."),
  lastName: Yup.string().required("Write your last name."),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  job: Yup.string().required("A job position is required.").max(255),
  message: Yup.string().required("Write a message.").max(255),
});

export default function FormCareers() {
  const form = useRef();

  const initialValues = {
    emailAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    job: "",
    message: "",
  };

  const submitForm = (values, { resetForm, setSubmitting }) => {
    emailjs
      .sendForm(
        "sendgrid_service_id",
        "career_form_template",
        form.current,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY,
      )
      .then(
        () => {
          setSubmitting(false);
          resetForm();
          alert("Successfully submitted your application!");
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <Container>
      <Wrapper>
        <TwoColumn>
          <h4 className="CircularBold">
            You might be the perfect addition to our growing Simba family.
          </h4>
          <Holder>
            <Formik
              validationSchema={SignUpSchema}
              initialValues={initialValues}
              onSubmit={submitForm}
              className="form"
            >
              {(formik) => (
                <Form ref={form}>
                  <h4>
                    Contact us to start a conversation about an exciting new
                    future.
                  </h4>
                  <div className="fieldset firstName">
                    <label htmlFor="firstName" className="label">
                      First Name
                    </label>
                    <Field
                      className="input"
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="e.g. Firstname"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="firstName"
                      component="div"
                    />
                  </div>
                  <div className="fieldset lastName">
                    <label htmlFor="lastName" className="label">
                      Last Name
                    </label>
                    <Field
                      className="input"
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="e.g. Lastname"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="lastName"
                      component="div"
                    />
                  </div>
                  <div className="fieldset emailAddress">
                    <label htmlFor="emailAddress" className="label">
                      Email
                    </label>
                    <Field
                      className="input"
                      id="emailAddress"
                      type="email"
                      name="emailAddress"
                      placeholder="e.g. initialsurname@company.com"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="emailAddress"
                      component="div"
                    />
                  </div>
                  <div className="fieldset phoneNumber">
                    <label htmlFor="phoneNumber" className="label">
                      Phone
                    </label>
                    <Field
                      className="input"
                      id="phoneNumber"
                      type="text"
                      name="phoneNumber"
                      placeholder="e.g. +61 412 345 678"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="phoneNumber"
                      component="div"
                    />
                  </div>
                  <div className="fieldset job">
                    <label htmlFor="job" className="label">
                      Job Position
                    </label>
                    <Field
                      className="input"
                      id="job"
                      type="text"
                      name="job"
                      placeholder="Job Position"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="job"
                      component="div"
                    />
                  </div>
                  <div className="fieldset message">
                    <label htmlFor="message" className="label">
                      Message
                    </label>
                    <Field
                      name="message"
                      as="textarea"
                      className="textarea"
                      placeholder="Type message here"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="message"
                      component="div"
                    />
                  </div>
                  <button
                    className="button outline large"
                    disabled={!formik.isValid || !formik.dirty}
                    type="submit"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </Holder>
        </TwoColumn>
      </Wrapper>
    </Container>
  );
}
