import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import Container from "../atoms/Container";
import TwoColumn from "./TwoColumn";

const Wrapper = styled.div`
  color: ${(props) => props.theme.colours.white};
  background-color: ${(props) => props.theme.colours.navy};
`;

const Holder = styled.div`
  form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    label {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0.2rem 0;
    }

    input {
      width: 100%;
      padding: 0.5rem;
      ${(props) => props.theme.fluidType(-1)};
      border: 1px solid ${(props) => props.theme.colours.white} !important;
      color: ${(props) => props.theme.colours.white};

      &::placeholder {
        color: ${(props) => props.theme.colours.white} !important;
      }
    }

    .fieldset,
    button {
      width: auto;
    }

    .errorMsg {
      color: ${(props) => props.theme.colours.red};
      ${(props) => props.theme.fluidType(-1)};
      display: block;
      margin: 0.2rem 0;
    }
  }
`;

const SignUpSchema = Yup.object().shape({
  emailAddress: Yup.string().email("Email is not valid").max(255).required("E-mail is required"),
});

export default function FormNewsletter() {
  const submitForm = (values, { resetForm }) => {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open(
      "POST",
      "https://prod-18.australiasoutheast.logic.azure.com:443/workflows/359e4e6ea95f425d955d9bd0583227fd/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=sNodBX-bGK2Ri27uBbfdHzHDpq2elqRd2qXQ2FkUCPI",
      false,
    );
    xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlHttp.send(
      JSON.stringify({
        emailAddress: document.getElementById("emailAddress").value,
      }),
    );
    console.log(values);
    alert("Successfully submitted your email!");
    resetForm();
  };

  return (
    <Container>
      <Wrapper>
        <TwoColumn>
          <h4 className="CircularBold">Stay in the loop</h4>
          <Holder>
            <Formik
              validationSchema={SignUpSchema}
              initialValues={{ emailAddress: "" }}
              onSubmit={submitForm}
              className="form"
            >
              {(formik) => (
                <Form>
                  <h4>
                    Subscribe to our newsletter by entering your details in the
                    form below.
                  </h4>
                  <div className="fieldset input emailAddress">
                    <label htmlFor="emailAddress" className="label">
                      Email
                    </label>
                    <Field
                      className="input"
                      id="emailAddress"
                      type="email"
                      name="emailAddress"
                      placeholder="e.g. initialsurname@company.com"
                    />
                    <ErrorMessage
                      className="errorMsg"
                      name="emailAddress"
                      component="div"
                    />
                  </div>
                  <button
                    className="button inverse large"
                    style={{ width: "50%" }}
                    disabled={!formik.isValid || !formik.dirty}
                    type="submit"
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          </Holder>
        </TwoColumn>
      </Wrapper>
    </Container>
  );
}
