import React, { useState } from "react"
import styled from "styled-components"
import Container from "../atoms/Container"
import ConsciousCard from "../molecules/ConsciousCard"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Plus from "../../assets/images/plus.inline.svg"
import { PrismicRichText } from "@prismicio/react"

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 4rem 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 2rem 4rem;
  }
`

const GoalHolder = styled.div`
  background-color: ${props => props.theme.colours.grey};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 4rem;
  row-gap: 4rem;
  align-items: center;
  padding: 2rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 4fr 1fr;
    column-gap: 10rem;
  }
`

const CardHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
  justify-items: center;
  grid-gap: 2rem;
  margin: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 2rem 0 0 0;
  }
`

SliceConsciousCards.propTypes = {
  items: PropTypes.array.isRequired,
  primary: PropTypes.object.isRequired,
}

function SliceConsciousCards({ items, primary }) {
  const [explore, setExplore] = useState(false)

  return (
    <Container>
      <Holder>
        <h4 className="CircularBold">{primary.title.text}</h4>
        <GoalHolder>
          <PrismicRichText field={primary.measurable_goal.richText} />
          <GatsbyImage alt={primary.icon.alt || "Gatsby icon"} image={primary.icon.gatsbyImageData}
                       className="goal-icon" imgStyle={{ objectFit: "contain" }} />
        </GoalHolder>
        <CardHolder>
          {items.map((item, index) => {
            return index <= 2 && <ConsciousCard text={item.card_description.richText} type={item.card_type}
                                                title={item.card_title.text} />
          })}
          {explore && items.map((item, index) => {
            return index >= 3 && <ConsciousCard text={item.card_description.richText} type={item.card_type}
                                                title={item.card_title.text} />
          })}
        </CardHolder>
        {items.length > 3 && !explore &&
          <button className="button" style={{
            outline: "none",
            border: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
                  onClick={() => setExplore(true)}>
            <h6 className="CircularBold" style={{ color: "#24B974" }}>View more</h6><Plus /></button>}
      </Holder>
    </Container>
  )
}

export default SliceConsciousCards