import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../atoms/Container";

const Background = styled.div`
  background-color: ${(props) => props.theme.colours.white};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  margin: 0 1rem;
  padding: 2rem 0;
  column-gap: 4rem;
  border-bottom: 1px solid ${(props) => props.theme.colours.navy};

  h4 {
    margin: 0 0 2rem 0;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 4rem 0;
    margin: 0 4rem;
    h4 {
      margin: 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  gap: 4rem;
  align-items: center;
`;

export default function SliceTitleHero({ logos, slice }) {
  return (
    <Container>
      <Background>
        <h4>{slice.title.text}</h4>
        <Grid>
          {logos.map((logo, i) => (
            <GatsbyImage
              key={i}
              imgStyle={{
                objectFit: "contain",
                maxWidth: "150px",
              }}
              image={logo.hero_image.gatsbyImageData}
              alt={logo.hero_image.alt}
            />
          ))}
        </Grid>
      </Background>
    </Container>
  );
}

SliceTitleHero.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.object,
};

