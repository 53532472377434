import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Holder = styled.div`
  display: inline-block;
  height: 12rem;
  width: 12rem;
  border-radius: 10px;
  overflow: hidden;
`

UNSDCard.propTypes = {
  image: PropTypes.object,
}

function UNSDCard({ image }) {
  return (
    <Holder>
      {image}
    </Holder>
  )
}

export default UNSDCard